import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import HomePage from "./pages/HomePage";
import BuyPage from "./pages/BuyPage/BuyPage";
import LandingPage from "./pages/landingPage/landingPage";
import About from "./pages/AboutPage";

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/legacy" component={LandingPage} />
        <Route exact path="/about" component={About} />
        <Route path="/" component={BuyPage} />
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
