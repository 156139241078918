import React from "react";
import Navbar from "../../components/navbar/navbar";
import classNames from "./about.module.scss";
import "./landingPage.css";
import Footer from "../../layout/Footer";

const About = () => {
  return (
    <>
      <div>
        <Navbar />
        <div className={classNames.section1}>
          <div>The Vision</div>
          <div>
            Energy Coins is dedicated to transforming the global energy sector
            through the innovative fusion of clean energy solutions and
            blockchain technology. Our vision is to establish a world where
            clean energy is universally accessible, affordable, and sustainable.
          </div>
        </div>
        <div className={classNames.section2}>
          <div>How It Works</div>
          <div>
            Our platform enables consumers to acquire Energy Coins, granting
            them preferential rates on electricity consumption. These tokens are
            underpinned by tangible energy assets, ensuring transparency and
            security in all transactions. By incentivizing the adoption of clean
            energy, we aim to accelerate the transition to sustainable power
            generation globally.
          </div>
        </div>
        <div className={classNames.section2}>
          <div>Our Values</div>
          <div>
            At Energy Coins, we are driven by a steadfast commitment to
            sustainability, innovation, and social responsibility. We leverage
            technology to address pressing environmental challenges and foster
            positive change for future generations. Through advocacy for
            renewable energy adoption and community engagement, we strive to
            make a meaningful impact at local and global levels.
          </div>
        </div>
        <div className={classNames.section2}>
          <div>Join Us!</div>
          <div>
            Join us in our quest for a cleaner, greener future. Whether you're
            passionate about clean energy, blockchain technology, or
            sustainability, there's a place for you in the Energy Coins
            community. Together, we can reshape the energy landscape and build a
            brighter tomorrow.
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default About;
