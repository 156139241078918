import React, { useContext, useState, useEffect } from "react";
import ifcIcon from "../../../static/images/logos/influenceIcon.svg";
import { ChatsContext } from "../../../context/GlobalContext";

//purchase smart contract
import { masterSmartContract, PurchaseSmartContract } from "../../../config";

//web3 Imports
import { ethers } from "ethers";
import ErrorMessage from "./ErrorMessage";
import TxList from "./TxList";
import './BuyIFC.css';
import Web3 from "web3";
import {
  GXTToken,
  IFCToken,
} from "../../../config";

//web3 Functions
// const startPayment = async ({ setError, setTxs, ether, addr }) => {
//   try {
//     if (!window.ethereum)
//       throw new Error("No crypto wallet found. Please install it.");

//     // await window.ethereum.send("eth_requestAccounts",[]);
//     const provider = new ethers.providers.Web3Provider(window.ethereum);
//     await provider.send("eth_requestAccounts", []);
//     const signer = provider.getSigner();
//     ethers.utils.getAddress(addr);
//     const tx = await signer.sendTransaction({
//       to: addr,
//       value: ethers.utils.parseEther(ether),
//     });
//     // console.log({ ether, addr });
//     // console.log("tx", tx);
//     window.alert("Transaction hash: " + tx.hash);
//     setTxs([tx]);
//   } catch (err) {
//     window.alert("Transaction failed");
//     console.log(err);
//     setError(err.message);
//   }
// };


export default function BuyIFC({ coin }) {
  const { coinETH } = useContext(ChatsContext);

  //web3 States
  const [error, setError] = useState();
  const [txs, setTxs] = useState([]);
  const [addressInput, setAddressInput] = useState("0x932066288B3E2DC5564B1c6526cE58b26539d1F3");
  const [etherInput, setEtherInput] = useState(1);
  const [isConnected, setIsConnected] = useState(true);
  const [ethBalance, setEthBalance] = useState("");
  const [connectedAddress, setConnectedAddress] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState('ETH');
  // Define the contract ABI and contract address
  const contractABI = masterSmartContract.abi;
  const contractAddress = masterSmartContract.address;

  // Create a provider using window.ethereum
  const provider = new ethers.providers.Web3Provider(window.ethereum);

  //New Purchase Function
  // const purchaseTokenWithETH = async () => {
  //   try {
  //     await window.ethereum.enable();
  //     const signer = provider.getSigner();
  //     const contract = new ethers.Contract(contractAddress, contractABI, signer);
  //     const rate = await contract.getExchangeRateEth("0xBC2D5306db2D7b70681Ec68Aa81DF8056cCcc2C9");
  //     console.log(rate.toString());
  //     if (rate <= 0) {
  //       throw new Error('Exchange rate not available');
  //     }
  //     let z = etherInput * 0.0004
  //     z = z.toString()
  //     const ethAmount = ethers.utils.parseEther(z);
  //     const toAmount = ethAmount.mul(rate).div(ethers.utils.parseEther('1'));
  //     const tx = await contract.purchaseTokenWithETH("0xBC2D5306db2D7b70681Ec68Aa81DF8056cCcc2C9", { value: ethAmount });
  //     await tx.wait();
  //     console.log('Tokens purchased:', toAmount.toString());
  //   } catch (error) {
  //     console.error('Failed to purchase tokens with ETH:', error);
  //   }
  // };

  //Old Purchase Function
  //web3 Functions
  const startPayment = async ({ ether, addr }) => {
    try {
      if (!window.ethereum)
        throw new Error("No crypto wallet found. Please install it.");
      await window.ethereum.send("eth_requestAccounts", []);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();
      ethers.utils.getAddress(connectedAddress);
      let z = etherInput * 0.0000220000000
      z = z.toString()
      const tx = await signer.sendTransaction({
        to: "0x932066288B3E2DC5564B1c6526cE58b26539d1F3",
        value: ethers.utils.parseEther(z),
      });
      // console.log({ ether, addr });
      // console.log("tx", tx);
      window.alert("Transaction hash: " + tx.hash);
      setTxs([tx]);
    } catch (err) {
      window.alert("Transaction failed");
      console.log(err);
    }
  };

  const withdrawETH = async () => {
    try {
      await window.ethereum.enable();
      const contractAddress = "0xec946d41e4f427cf41c5ce27e4fa8040721ffd31";
      const contractABI = PurchaseSmartContract.abi;
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, contractABI, signer);

      // Estimate gas required for the transaction
      const gasLimit = await contract.estimateGas.withdrawETH();

      // Send transaction with estimated gas limit
      const tx = await contract.withdrawETH({
        gasLimit: gasLimit
      });

      await tx.wait();
      console.log('ETH withdrawn');
    } catch (error) {
      console.error('Failed to withdraw ETH:', error);
    }
  };


  const swapTokens = async (_fromToken, _toToken, _fromAmount) => {
    try {
      // First handle approve
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []);
      const signer = await provider.getSigner();
      const erc20 = new ethers.Contract("0xc27F1983660A53E6a54C15eAeDbE032578aa57E5", IFCToken.abi, signer);
      let z = etherInput * 0.0000220000000
      z = z.toString()
      const GXTCustomerInput = ethers.utils.parseEther(z, 18)
      const tx = await erc20.approve(IFCToken.address, GXTCustomerInput);
      console.log(`Approve transaction hash: ${tx.hash}`);
      // Wait for approve transaction to be mined
      const approveReceipt = await tx.wait();
      console.log("Approve transaction mined. Receipt:", approveReceipt);
      // Only proceed with swapping after approve transaction is mined
      if (approveReceipt.status === 1) {
        const contract = new ethers.Contract(contractAddress, contractABI, signer);
        const transaction = await contract.swapTokens("0xc27F1983660A53E6a54C15eAeDbE032578aa57E5", "0xBC2D5306db2D7b70681Ec68Aa81DF8056cCcc2C9", "4000");
        console.log('Tokens swapped successfully');
      } else {
        console.error('Approve transaction failed');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const detectCurrentProvider = () => {
    let provider;
    if (window.ethereum) {
      provider = window.ethereum;
    } else if (window.web3) {
      provider = window.web3.currentProvider;
    } else {
      console.log("Non-ethereum browser detected. You should install Metamask");
    }
    return provider;
  };

  //Web3 connection to MetaMask
  const onConnect = async () => {
    try {
      const currentProvider = detectCurrentProvider();
      if (currentProvider) {
        await currentProvider.request({ method: "eth_requestAccounts" });
        const web3 = new Web3(currentProvider);
        const userAccount = await web3.eth.getAccounts();
        const account = userAccount[0];
        let ethBalance = await web3.eth.getBalance(account);
        ethBalance = web3.utils.fromWei(ethBalance, "ether");
        //check for network
        const networkId = await web3.eth.net.getId();
        if (networkId !== 8453) {
          await currentProvider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: "0x2105" }],
          });
        }
        setEthBalance(ethBalance);
        window.alert(`Connected to ${currentProvider.selectedAddress}`);
        setConnectedAddress(currentProvider.selectedAddress);
        setIsConnected(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  //web3 Functions
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError();
    await startPayment({
      ether: etherInput,
      addr: addressInput,
    });
  };

  const handleChangeEth = (event) => {
    setEtherInput(event.target.value);
  };

  return (
    <div className="BuyCard">
      <div className="contents">
        <div className="group">
          <div className="label">How Much ENERGY Do You Want?</div>
          <div className="inpWraper">
            <input id="coin2"
              type="text"
              placeholder={`1`}
              onChange={handleChangeEth}
              value={etherInput}
              name="ether"
              className="inputcontainer aactive"
            />
            <div className="coinContainer">
              <p>ENERGY</p>
            </div>
          </div>
        </div>
        <div className="group">
          <div className="label">You Will Pay</div>
          <div className="inpWraper">
            <input id="coin1"
              type="text"
              placeholder={selectedCurrency === 'USDT' ? (etherInput * 0.000022) : (etherInput * 0.000022)}
              className="inputcontainer"
              disabled
            />
            <div className="buttonContainer">
              {/* <button className={`miniButton ${selectedCurrency === 'USDT' ? 'actives' : ''}`} onClick={() => setSelectedCurrency('USDT')}>USDT</button> */}
              <button className={`miniButton ${selectedCurrency === 'ETH' ? 'actives' : ''}`} onClick={() => setSelectedCurrency('ETH')}>ETH</button>
            </div>
          </div>
        </div>
        {isConnected ?
          <div>
            <button className="btBuyButton" onClick={onConnect}>Connect Wallet</button>
          </div> :
          <div>
            <p className="label labelActive" style={{ height: '20px', lineHeight: '20px' }}>Connected Wallet: {connectedAddress}</p>
            <button className="btBuyButton" onClick={selectedCurrency === 'USDT' ? swapTokens : startPayment}>Buy ENERGY</button>
            {/* <button className="btBuyButton" onClick={selectedCurrency === 'USDT' ? swapTokens : withdrawETH}>withdraw ETH</button> */}
          </div>}
      </div>
    </div>
  );
}
