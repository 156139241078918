import React from "react";
import "./footer.css";
import { FaDiscord, FaTiktok, FaTwitter, FaTelegram } from "react-icons/fa";
import mainlogo from "../static/images/navbar/mainlogo.svg";

const Footer = () => {
  const openLink = (url) => {
    window.open(url, '_blank');
  };
  return (
    <>
      <div className="landing-sec6">
        <h1>Register for Airdrop!</h1>
        <div>
          Happy this found you, sign up and get
          <br />
          some free $ENERGY!
        </div>
        <button>Start Now</button>
      </div>
      <div className="sec-footer">
        <div className="sec-div1">
          <img src={mainlogo} alt="" style={{ height: "90px" }} />
          <div className="social-icons">
            <FaDiscord onClick={() => openLink('https://discord.gg/2dyCaZVXq4')} />
            <FaTiktok onClick={() => openLink(' https://www.tiktok.com/@energy.coin?_t=8lbqSpve0Gs&_r=1')} />
            <FaTwitter onClick={() => openLink('https://x.com/energy_coinx?s=21&t=Y5OZZQY9T62Vm7N4nNVQfw')} />
            <FaTelegram onClick={() => openLink('https://t.me/energycoinx ')} />
          </div>
        </div>
        <div className="sec-div2">
          <div>$Energy</div>
          <div>About Us</div>
          <div>Airdrop</div>
          <div>Assets</div>
        </div>
        <div className="sec-div2" style={{ paddingLeft: "100px" }}>
          <div>Quick Link</div>
          <div>Contests</div>
          <div>Resources</div>
          <div>Whitepaper</div>
        </div>
      </div>
      <div className="copyright">© 2024 Energy Coin All rights reserved</div>
    </>
  );
};

export default Footer;
