import React, { useEffect, useState } from "react";
import {  useHistory } from "react-router-dom";
import "./navbar.css";
import mainlogo from "../../static/images/navbar/mainlogo.svg";
import downarrow from "../../static/images/navbar/downarrow.svg";
import base from "../../static/images/navbar/base.svg";
import ham from "../../static/images/navbar/ham.svg";
import cross from "../../static/images/navbar/cross.svg";
import { useStore } from "../store/store";

const Navbar = () => {
  let history = useHistory();
  const { isOpen, setOpen, navOpen, setNavOpen } = useStore();

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      history.push('/buy');
    }
  };

  const [selectedNav, setSelectedNav] = useState('Home')

  useEffect(() => {
    console.log(history, "kjwbeflkjebfler")
    const path = history.location.pathname;
    if(path === "/"){
      setSelectedNav("Home")
    }else if(path === "/about"){
      setSelectedNav("About")
    }
  }, [history])

  return (
    <div className="nav-container">
      <div className="nav-left">
        <div className="nav-logo-box" 
         onClick={() => history.push("/")}
         onKeyDown={handleKeyDown}
         role="button" 
          tabIndex={0} 
        >
          <img className="nav-logo" src={mainlogo} alt="" />
        </div>
        {/* <button
          className="drop-box"
          onClick={() => setOpen(!isOpen)}
        >
          <div className="drop-menu-box">
            <button className="drop-menu">
              BY&nbsp;<span className="drop-menu-head">AIR</span>
            </button>
            <div
              className="drop-img-box"
              style={{ transform: isOpen ? "rotate(180deg)" : "" }}
            >
              <img className="drop-img" src={downarrow} alt="" />
            </div>
          </div>
        </button> */}
      </div>
      {/*{selectedNav === "Home" ? "":
      <div className="nav-menu-box">
          <button className={selectedNav === "Home" ? "selected-nav" :"nav-menu"}
            onClick={e => history.push("/")}
          >
            Home
          </button>
           <button className={selectedNav === "About" ? "selected-nav" :"nav-menu"}
           onClick={e => history.push("/about")}
           >
            About
          </button>
           <button className={selectedNav === "Rewards" ? "selected-nav" :"nav-menu"}>Rewards</button>
           <button className={selectedNav === "Blogs" ? "selected-nav" :"nav-menu"}>Blogs</button>
           <button className={selectedNav === "Whitepaper" ? "selected-nav" :"nav-menu"} 
            onClick={e =>  window.open('https://drive.google.com/file/d/1JS48xIHgRR6JiamWgD3wNMGDnUpHx2ed/view', '_blank')}
           >Whitepaper</button>
        </div>}*/}
      <div className="nav-right">
        
        <div className="nav-buttons">
          <button className="nav-btn1"  onClick={() => window.open('https://basescan.org/token/0xb8ddb4837edf55c2702e5cc64861bc017d68f056?a=0xd2f9db82eb0e8e9a683077ab7bcbf8de9137c5ac', "_blank")}>
            <img className="nav-btn1-img" src={base} alt="" />
           <div>Base</div>
          </button>
          <button className="nav-btn2" onClick={() => history.push("/buy")}>
            Buy
          </button>
        </div>
        <div
          className="nav-ham-box"
          onClick={() => setNavOpen(!navOpen)}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === "Space") {
              setNavOpen(!navOpen);
            }
          }}
          role="menuitem"
          tabIndex={0}
        >
          <img src={navOpen ? cross : ham} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
