import React, { useState, useEffect, useMemo, useContext } from "react";
import { ChatsContext } from "../../context/GlobalContext";
import BuyIFC from "./BuyIFC/BuyIFC";
import StakeIFC from "./SwapIFC/SwapIFC";
import "./TransactionCardHome.css";

function TransactionCardHome({ coin, setCoin, coinList }) {
  const { tabSelected, setTabSelected } = useContext(ChatsContext);

  useEffect(() => {
    setTabSelected("Buy");
  }, []);

  const handleTabClick = (tab) => {
    setTabSelected(tab);
    console.log(tab);
  };

  const tabComponent = useMemo(() => {
    switch (tabSelected) {
      case "Buy":
        return <BuyIFC coin={coin} coinList={coinList} setCoin={setCoin} />;
      // case "Swap":
      //   return <StakeIFC coin={coin} />;
      default:
        return null;
    }
  }, [tabSelected]);

  return (
    <div className="transactionCard">
      <div className="tabs">
        <div
        //on selected border bottom css to be added 
          className={` ${tabSelected === "Buy" ? "active" : ""} `}
          onClick={() => handleTabClick("Buy")}
          role="tab"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === "Space") {
              handleTabClick("Buy");
            }
          }}
        >
          Buy
        </div>
        <div
          className={` ${tabSelected === "Swap" ? "active" : ""}`}
          // onClick={() => handleTabClick("Swap")}
          role="tab"
          tabIndex={0}
          // onKeyDown={(e) => {
          //   if (e.key === "Enter" || e.key === "Space") {
          //     handleTabClick("Swap");
          //   }
          // }}
        >
          Swap
        </div>
      </div>
      {tabComponent}
    </div>
  );
}

export default TransactionCardHome;