import React, { useLayoutEffect, useEffect } from "react";
import Navbar from "../../components/navbar/navbar";
import "./landingPage.css";
import land1 from "../../static/images/landing/land1.svg";
import land2 from "../../static/images/landing/land2.svg";
import land3 from "../../static/images/landing/land3.svg";
import dgp from "../../static/images/landing/dgp.svg";
import gx from "../../static/images/landing/gx.svg";
import { useStore } from "../../components/store/store";
import Dropdown from "../../components/DropDown/dropdown";
import gpt from "../../static/images/navbar/gpt.svg";
import { useHistory } from "react-router-dom";
import Mobmenu from "../../components/mobmenu/mobmenu";
import top from "./top.svg";
import problem from "./problem.svg";
import solution from "./solution.svg";

import comp1 from "./comp1.svg";
import comp2 from "./comp2.svg";
import comp3 from "./comp3.svg";

import s2img from "./s2.svg";

import { FaDiscord, FaTiktok, FaTwitter, FaTelegram } from "react-icons/fa";
import ReactPlayer from "react-player";
import Footer from "../../layout/Footer";

const LandingPage = () => {
  let history = useHistory();
  const { isOpen, setOpen, navOpen, setNavOpen, mobsize, setMobsize } =
    useStore();

  const dividerData = [
    {
      title: "60+",
      subTitle: "Supported Assets",
    },
    {
      title: "10k+",
      subTitle: "Investors",
    },
    {
      title: "$4M+",
      subTitle: "Assets Invested",
    },
    {
      title: "70k+",
      subTitle: "Interest Payments",
    },
  ];

  useLayoutEffect(() => {
    function updateSize() {
      setMobsize(window.innerWidth);
      if (window.innerWidth > 900) {
        setNavOpen(false);
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, [mobsize]);

  return (
    <div className="landing-container">
      <Navbar />
      <div>
        {isOpen ? (
          <Dropdown />
        ) : !navOpen ? (
          <>
            <div className="landing-sec1">
              <div>
                <div className="secTitle">The Future of Energy, Tokenized</div>
                <div className="secDesc">
                  The $Energy Coin movement envisions a world where clean and
                  affordable energy is accessible to every corner of the globe.
                  $Energy is Built on BASE.
                </div>
              </div>
              <div>
                {/* <img src={top} alt="" /> */}
                <ReactPlayer
                  playing
                  url="/hero.mp4"
                  height="400px"
                  width="500px"
                  controls="true"
                  style={{ borderRadius: "20px" }}
                />
              </div>
            </div>
            <div className="landing-sec4">
              <img src={comp1} alt="" />
              <img src={comp2} alt="" />
              <img src={comp3} alt="" />
            </div>
            <div className="landing-sec2" style={{ background: "#F1F1F1" }}>
              <div>
                <div className="secTitle">The Problem</div>
                <div className="secDesc">
                  The traditional energy sector faces significant challenges,
                  including rising costs, environmental concerns, and limited
                  access for underserved communities. Fossil fuel dependency and
                  centralized energy grids contribute to inefficiencies and
                  environmental degradation. $Energy Coins addresses these
                  issues by offering an alternative approach that combines clean
                  energy generation with blockchain technology, ensuring
                  affordability, accessibility, and sustainability.
                </div>
              </div>
              <div>
                <img src={s2img} alt="" />
              </div>
            </div>
            {/* <div className="sec-divider">
              <div className="sec-div1">
                <img src={mainlogo} alt="" />
                <div>Where Clean Energy Meets Blockchain or Tech</div>
              </div>
              <div className="sec-div2">
               {dividerData?.map(item => (
                <div>
                  <div className="numberText">{item?.title}</div>
                  <div className="descText">{item?.subTitle}</div>
                </div>
               ))}
              </div>
            </div> */}
            <div className="landing-sec2" style={{ background: "#F1F1F1" }}>
              <div>
                <img src={solution} alt="" />
              </div>
              <div>
                <div className="secTitle">The Solution</div>
                <div className="secDesc">
                  $Energy Coins serve as the catalyst for transforming the
                  energy landscape. By leveraging blockchain, we establish a
                  transparent and secure platform that connects energy producers
                  directly with consumers. Through the issuance of $Energy
                  Coins, users gain access to discounted electricity generated
                  by our cutting-edge facilities. This solution not only reduces
                  the environmental impact of energy consumption but also
                  democratizes access to affordable power, fostering a global
                  community{" "}
                  <span
                    style={{ textDecoration: "underline", fontStyle: "italic" }}
                  >
                    Read More
                  </span>
                </div>
              </div>
            </div>
            <div className="landing-sec5">
              {Array(3)
                .fill("")
                .map((item) => (
                  <div></div>
                ))}
            </div>
            <Footer />
          </>
        ) : (
          <Mobmenu />
        )}
      </div>
    </div>
  );
};

export default LandingPage;
